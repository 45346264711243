<template>
  <v-container style="background: none">
    <v-row class="align-center border">
      <v-col class="align-center col-lg-4 pl-5 itemText pt-2 pb-2">
        {{ $t('MachID') }}
      </v-col>
      <v-col class="align-center text-center col-lg-8 itemText pt-2 pb-2">
        {{ $t('Number of kilometers traveled') }}
      </v-col>
    </v-row>
    <LatLngItem
        v-for="item in latLngData"
        :kilometers="item.distanceTravelled"
        :mach-id="item.machId"
        :key="item.machId"
    />
  </v-container>
</template>

<script>
import LatLngItem from "./LatLngItem";

export default {
  name: "LatLngTable",
  components: {LatLngItem},
  props: {
    latLngData: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
.itemText {
  font-weight: 600;
  color: #5A5A5A;
  font-size: 14px;
}

.border {
  border-top: 1px solid rgba(90, 90, 90, 0.4);
  border-bottom: 1px solid rgba(90, 90, 90, 0.4);
}
</style>