<template>
  <div>
    <canvas id="planet-chart"></canvas>
  </div>
</template>

<script lang="js">
import Chart from 'chart.js'
export default {
  name: "CountryChart",
  props:["countryData", "countData"],
  data() {
    return {
      planetChartData: {
        type: "horizontalBar",
        data: {
          labels: this.$props.countryData,
          datasets: [
            {
              data: this.$props.countData,
              backgroundColor: "#002D5F",
              borderColor: "#002D5F",
              borderWidth: 1,

            },
          ]
        },
        options: {
          legend: {
              display: false
          },
          responsive: true,

          lineTension: 1,
          scales: {
            xAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  padding: 25
                },
                grid: {
                  offset: true
                },
                gridLines: {
                  borderDash: [8, 2],
                }
              }
            ],
            yAxes: [
              {
                gridLines: {
                  display: false
                }
              }
            ]
          }
        }
      }
    }
  },
  mounted() {
    const ctx = document.getElementById('planet-chart');
    new Chart(ctx, this.planetChartData);
  }
}

</script>

<style scoped>

</style>
