<template>
  <v-container style="background: none">
    <v-row class="align-center border">
      <v-col class="align-center col-lg-4 pl-5 itemText pt-2 pb-2">
        {{$t('Screen')}}
      </v-col>
      <v-col class="align-center text-center col-lg-8 itemText pt-2 pb-2">
        {{$t('Share of all users')}}
      </v-col>
    </v-row>

    <ScreenActivityItem
        v-for="(item, index) in screenData"
        :percentage="item.percentage"
        :bordered="index !== screenData.length - 1"
        :key="`Screen-Statistics-${item.screen}-${index}`"
        :screen="item.screen"
    />
  </v-container>
</template>

<script>
import ScreenActivityItem from "@/components/ScreenActivityItem";

export default {
  name: "ScreenActivityTable",
  components: {ScreenActivityItem},
  props: {
    screenData: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
.itemText {
  font-weight: 600;
  color: #5A5A5A;
  font-size: 14px;
}

.border {
  border-top: 1px solid rgba(90, 90, 90, 0.4);
  border-bottom: 1px solid rgba(90, 90, 90, 0.4);
}
</style>
