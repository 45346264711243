<template>
  <div>
    <v-skeleton-loader
        v-for="item in currData"
        class="mt-2"
        type="paragraph"
        :key="item"
    ></v-skeleton-loader>
  </div>
</template>

<script>
export default {
  name: "ScreenActivitySkeletonLoader",
  computed: {
    currData () {
      return [...Array(10).keys()]
    }
  }
}
</script>

<style scoped>

</style>
