<template>
  <div>
    <v-row>
      <v-col class="col-lg-12">
        <v-skeleton-loader
            v-for="item in currData"
            class="mt-2"
            type="paragraph"
            :key="item"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "MachAvgActivityTableSkeleton",
  computed: {
    currData () {
      return [...Array(7).keys()]
    }
  }
}
</script>

<style scoped>

</style>