<template>
  <div id="gpsMap" ref="gpsMap" class="map">

  </div>
</template>

<script>
import mapboxgl, {LngLat} from "mapbox-gl";
import 'mapbox-gl/dist/mapbox-gl.css';
import {padTo2Digits} from "@/utils/date";

export default {
  name: "Map",
  mounted() {
    mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_KEY
    this.map = new mapboxgl.Map({
      container: document.getElementById('gpsMap') || 'gpsMap',
      style: "mapbox://styles/mapbox/light-v10",
      center: this.center,
      zoom: 5,
    });

    this.map.on('load', () => {
      this.loaded = true
    });
  },
  data: function () {
    return {
      map: '',
      center: new LngLat(14.505751, 46.056946),
      loaded: false,
      allowedIds: [
          'GPS_LATITUDE',
          'GPS_LONGITUDE',
          'LIVING_MEASURED_TEMPERATURE',
          'MAINS_SUPPLY_VALUE'
      ]
    }
  },
  props: {
    markers: {
      type: Array,
      required: true
    }
  },
  watch: {
    markers: function (newVal) {
      if (newVal && newVal.length > 0 && this.loaded) {
        this.drawMarkers()
      }
    },
    loaded: function (newVal) {
      if (newVal && this.markers && this.markers.length > 0) {
        this.drawMarkers();
      }
    }
  },
  methods: {
    drawMarkers() {
      this.map.addSource('machs', {
        type: 'geojson',
        data: {
          type: "FeatureCollection",
          "crs": {"type": "name", "properties": {"name": "urn:ogc:def:crs:OGC:1.3:CRS84"}},
          features: this.markers.map((it) => {
            return {
              type: "Feature",
              properties: {
                machId: it.machId,
                timestamp: it.timestamp
              },
              geometry: {
                type: "Point",
                coordinates: [it.longitude, it.latitude, 0.0]
              }
            }
          })
        },
        cluster: true,
        clusterMaxZoom: 14, // Max zoom to cluster points on
        clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
      });

      this.map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'machs',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#002D5F',
            100,
            '#5B5B5B',
            750,
            '#888888',
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            100,
            30,
            750,
            40
          ]
        }
      });

      this.map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'machs',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
        },
        paint: {
          'text-color': '#FFFFFF'
        }
      });

      this.map.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'machs',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': '#002D5F',
          'circle-radius': 4,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#5B5B5B'
        }
      });

      this.map.on('click', 'clusters', (e) => {
        const features = this.map.queryRenderedFeatures(e.point, {
          layers: ['clusters']
        });
        const clusterId = features[0].properties.cluster_id;
        this.map.getSource('machs').getClusterExpansionZoom(
            clusterId,
            (err, zoom) => {
              if (err) return;

              this.map.easeTo({
                center: features[0].geometry.coordinates,
                zoom: zoom
              });
            }
        );
      });
      this.map.on('click', 'unclustered-point', async (e) => {
        const coordinates = e.features[0].geometry.coordinates.slice();
        const timestamp = e.features[0].properties.timestamp;
        const date = new Date(timestamp)
        const displayHorus = [
          padTo2Digits(date.getHours()),
          padTo2Digits(date.getMinutes()),
          padTo2Digits(date.getSeconds())
        ].join(':')
        const displayDate = [
          padTo2Digits(date.getDate()),
          padTo2Digits(date.getMonth() + 1),
          date.getFullYear(),
        ].join('/');

        const machId = e.features[0].properties.machId

        const popup = new mapboxgl.Popup()
            .setLngLat(coordinates)
            .setHTML(
                `${this.$t('Fetching data for Mach:')} ${machId}`
            )
            .addTo(this.map);

        try {
          const data = (await this.$http.get(`${process.env.VUE_APP_API_URL}/vehicles/${machId}/basicInfo`)).data
          if (!popup.isOpen()) {
            return
          }
          popup.remove()
          const chassis = data.chassis ? data.chassis : this.$t('Unknown')
          const engine = data.engine ? data.engine : this.$t('Unknown')
          const sensorsHTML = data.latestValues.filter((it) => this.allowedIds.find((it2 => it2 === it.valueId))).map((it) => {
            return `<b>${this.convertIdToString(it.valueId)}: </b>${it.measuredValue} ${this.getValueIdUnit(it.valueId)}<br>`
          }).join('')
          new mapboxgl.Popup()
              .setLngLat(coordinates)
              .setHTML(
                  `<div>
                    <b style="font-size: 0.9rem; margin-bottom: 15px">${this.$t('Basic vehicle information')}</b><br>
                    <b>${this.$t('MachID')} </b>${data.vehicle.machId} <br>
                    <b>${this.$t('Location updated')} </b> ${displayHorus} ${displayDate}<br>
                    <b>${this.$t('Model')} </b>${data.vehicle.model} <br>
                    <b>${this.$t('Vehicle type')} </b>${data.vehicle.type}<br>
                    <b>${this.$t('Chassis number:')} </b>${chassis} <br>
                    <b>${this.$t('Engine model:')}</b> ${engine} <br>
                    <hr>
                    <b style="font-size: 0.9rem; margin-bottom: 15px; margin-top:15px">${this.$t('Last measured values in the vehicle')}</b><br>
                    ${sensorsHTML}
                   </div>
                  `
              )
              .addTo(this.map);
        } catch (e) {
          if (!popup.isOpen()) {
            return
          }
          popup.remove()
          new mapboxgl.Popup()
              .setLngLat(coordinates)
              .setHTML(this.$t('There was an error retrieving the data'))
              .addTo(this.map)
        }
      });

      this.map.on('mouseenter', 'clusters', () => {
        this.map.getCanvas().style.cursor = 'pointer';
      });
      this.map.on('mouseleave', 'clusters', () => {
        this.map.getCanvas().style.cursor = '';
      });
    },
    convertIdToString(valueId) {
      switch (valueId) {
        case 'GPS_LATITUDE':
          return this.$t('Latitude')
        case 'GPS_LONGITUDE':
          return this.$t('Longitude')
        case 'LIVING_MEASURED_TEMPERATURE':
          return this.$t('Vehicle interior temperature')
        case 'MAINS_SUPPLY_VALUE':
          return this.$t('Connection to 220V')
      }
    },
    getValueIdUnit(valueId) {
      switch (valueId) {
        case 'GPS_LATITUDE':
          return ''
        case 'GPS_LONGITUDE':
          return ''
        case 'LIVING_MEASURED_TEMPERATURE':
          return 'C'
        case 'MAINS_SUPPLY_VALUE':
          return ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../node_modules/mapbox-gl/dist/mapbox-gl.css";

#gpsMap {
  height: 98vh;
  width: 100vw;
}

.marker {
  background-image: url("data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzNCAzNCI+CiAgICA8ZGVmcz4KICAgICAgICA8c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZDY1MDt9LmNscy0ye2ZpbGw6bm9uZTtzdHJva2UtbGluZWNhcDpyb3VuZDt9LmNscy0yLC5jbHMtMywuY2xzLTR7c3Ryb2tlOiMwMDA7fS5jbHMtM3tmaWxsOiNmZmY7fS5jbHMtMywuY2xzLTR7c3Ryb2tlLW1pdGVybGltaXQ6MTA7fS5jbHMtNHtmaWxsOiNjZWNlY2U7fQogICAgICAgIDwvc3R5bGU+CiAgICA8L2RlZnM+CiAgICA8Y2lyY2xlIGlkPSJFbGxpcHNlXzEwLTIiIGRhdGEtbmFtZT0iRWxsaXBzZSAxMC0yIiBjbGFzcz0iY2xzLTEiIGN4PSIxNyIgY3k9IjE3IiByPSIxNyIvPgogICAgPHBhdGggaWQ9IlBhdGhfNDY0IiBkYXRhLW5hbWU9IlBhdGggNDY0IiBjbGFzcz0iY2xzLTIiIGQ9Ik00LjMzNDc2LDEzLjUwMzM4aDYuNTI3Ii8+CiAgICA8bGluZSBpZD0iTGluZV83NiIgZGF0YS1uYW1lPSJMaW5lIDc2IiBjbGFzcz0iY2xzLTIiIHgxPSI0LjMzNDc2IiB5MT0iMTUuNTAzMzgiIHgyPSI5LjMzNDc2IiB5Mj0iMTUuNTAzMzgiLz4KICAgIDxsaW5lIGlkPSJMaW5lXzc3IiBkYXRhLW5hbWU9IkxpbmUgNzciIGNsYXNzPSJjbHMtMiIgeDE9IjQuMzM0NzYiIHkxPSIxNy41MDMzOCIgeDI9IjguMzM0NzYiIHkyPSIxNy41MDMzOCIvPgogICAgPHBhdGggY2xhc3M9ImNscy0zIiBkPSJNMjkuNjY1MjQsMTYuNjI4ODJ2Mi45OTFhMS43NTI3OCwxLjc1Mjc4LDAsMCwxLTEuNzUyNzgsMS43NTI3OEgxMy41MTk1NmExLjkwMjc1LDEuOTAyNzUsMCwwLDEtMS45MDI3NC0xLjkwMjc1VjE3LjU1MjE1YTIuNDI1MzYsMi40MjUzNiwwLDAsMSwyLjQyNTM2LTIuNDI1MzZoMGEuMjYxMjQuMjYxMjQsMCwwLDAsLjI2MTI0LS4yNjEyNFYxMy4wNDg3N0EyLjI5Nzc1LDIuMjk3NzUsMCwwLDEsMTYuNjAxMTcsMTAuNzUxaDYuNDI4NThhMi4yOTc3MywyLjI5NzczLDAsMCwxLDEuOTYwNzYsMS4wOTk4MmwxLjM5MDgxLDIuMjc2NDdhMi4wODgxMywyLjA4ODEzLDAsMCwwLDEuNzgxODkuOTk5NDhoMEExLjUwMiwxLjUwMiwwLDAsMSwyOS42NjUyNCwxNi42Mjg4MloiLz4KICAgIDxjaXJjbGUgY2xhc3M9ImNscy0zIiBjeD0iMjQuOTMyNCIgY3k9IjIxLjE2OTg3IiByPSIyLjA3OTEiLz4KICAgIDxwb2x5Z29uIGNsYXNzPSJjbHMtNCIgcG9pbnRzPSIyMS4yNiAxNS4xIDIyLjMzOCAxMi45NjggMjEuMjA3IDEwLjc4NCAxOC45NDQgMTAuNzg0IDIwLjA3NSAxMi45NjggMTguOTk3IDE1LjEgMjEuMjYgMTUuMSIvPgogICAgPGNpcmNsZSBjbGFzcz0iY2xzLTMiIGN4PSIxNi42NDI2OCIgY3k9IjIxLjE2OTg3IiByPSIyLjA3OTEiLz48L3N2Zz4K");
  width: 100px;
  height: 100px;
}
</style>