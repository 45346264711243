<template>
  <v-skeleton-loader
      style="height: 200px; width: 100%"
      type="image"
  ></v-skeleton-loader>
</template>

<script>
export default {
  name: "MachAvgSeasonsSkeleton"
}
</script>

<style scoped>

</style>