<template>
  <div class="it">
    <div class="innerIt">

    </div>
  </div>
</template>

<script>
export default {
  name: "PulsingDot"
}
</script>

<style scoped>
.it {
  height: 10px;
  width: 10px;
  border-radius: 5px;
  background-color: rgb(0,128,0);
  position: relative;
}
.innerIt {
  background-color: rgba(0,128,0, 0.6);
  opacity: 0.4;
  height: 30px;
  width: 30px;
  right: -10px;
  top: -10px;
  border-radius: 100px;
  position: absolute;
  animation: blip;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}

@keyframes blip {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
</style>