var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"justify-center"},[_c('v-col',{staticClass:"mt-6",attrs:{"cols":"6"}},[_c('div',{staticClass:"latLngTable"},[(_vm.countries.length > 0 && _vm.models.length > 0 && _vm.machMapFetched)?_c('AdriaTable',{staticClass:"distanceTable",attrs:{"header":_vm.headers,"data":_vm.distanceReport.machDistances,"clickable":false,"name":'mach-distance-table',"hover":true},on:{"select":function (ref) {
	var item = ref.item;
	var selectableId = ref.selectableId;

	return _vm.handleSelect(item, selectableId);
}},scopedSlots:_vm._u([{key:"adria-table-machId-model",fn:function(ref){
var currentItem = ref.currentItem;
return [_vm._v(" "+_vm._s(currentItem.displayName)+" ")]}},{key:"adria-table-machId-country",fn:function(ref){
var currentItem = ref.currentItem;
return [_c('div',{staticClass:"countryRow"},[_c('div',[_vm._v(" "+_vm._s(currentItem.displayName)+" ")]),(currentItem.value !== 'All')?_c('div',{staticStyle:{"margin-left":"12px"}},[_c('v-img',{attrs:{"src":require(("@/assets/svg/countries/" + (currentItem.value) + ".svg")),"max-width":"10"}})],1):_vm._e()])]}}],null,false,210526182)}):_vm._e()],1)]),_c('v-col',{staticClass:"mt-6",attrs:{"cols":"6"}},[(!_vm.isDataEmpty)?_c('AdriaValueGraph',{attrs:{"id":"adria-value-graph-locations","value-label-data":_vm.valueLabelData,"value-data":_vm.valueData,"limited":false,"percentage":false,"min-height":'600px',"label-x":_vm.$t('Number of vehicles within range'),"label-y":_vm.$t('Number of kilometers traveled')}}):_vm._e(),(!_vm.isDataEmpty)?_c('p',{staticClass:"subTitle"},[_vm._v(" "+_vm._s(_vm.$t('Average mileage:'))+" "+_vm._s(_vm.avgKilometers)+" km ")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }