<template>
  <div class="mainContainer">
    <div class="genderContainer">
      <div class="genderItem"
           v-for="item in chartData"
           :key="item.name"
      >
        <div class="circle" :style="{backgroundColor: item.color}"/>
        <div class="ml-4 genderItemMainText" style="width: 80px">
          {{ item.name }}
          <v-tooltip bottom v-if="item.tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  small
                  color="gray"
                  v-bind="attrs"
                  v-on="on"
              >mdi-information</v-icon>
            </template>
            <span>{{item.tooltip}}</span>
          </v-tooltip>
        </div>
        <div class="ml-8 genderItemPercentText">
          {{ getPercentage(item) }} %
        </div>
      </div>
    </div>
    <div class="small-box ml-4">
      <canvas :id="id"></canvas>
    </div>
  </div>
</template>
<script lang="js">
import Chart from 'chart.js'

export default {
  name: "PieChart",
  props: ["chartData", "id"],
  data() {
    return {
      userGenderData: {
        type: "doughnut",
        data: {
          labels: this.$props.chartData.map((it) => it.name),
          datasets: [{
            data: this.$props.chartData.map((it) => it.count),
            backgroundColor: this.$props.chartData.map((it) => it.color)
          }]
        },
        options: {
          rotation: Math.PI,
          circumference: Math.PI,
          legend: {
            display: false
          },
          tooltips: {
            enabled: true
          },
          elements: {
            arc: {
              borderWidth: 0
            }
          }
        },
      }
    }
  },
  mounted() {
    const ctx = document.getElementById(this.id);
    new Chart(ctx, this.userGenderData);
  },
  computed: {
    totalCount() {
      let count = 0
      this.chartData.forEach((it) => {
        count += it.count
      })
      return count
    }
  },
  methods: {
    getPercentage(it) {
      const totalCount = this.totalCount
      return Math.round(it.count / (totalCount) * 100)
    }
  }
}
</script>

<style scoped>
.small-box {
  width: 150px;
  overflow: visible;
}

.mainContainer {
  display: flex;
  align-items: center;
  background: none;
}

.genderContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.circle {
  width: 14px;
  height: 14px;
  border-radius: 50%;
}

.genderItem {
  display: flex;
  justify-content: center;
  align-items: center;
}

.genderItemMainText {
  font-size: 12px;
  color: black;
}

.genderItemPercentText {
  font-weight: bold;
  font-size: 14px;
  color: black;
  margin-left: auto;
}
</style>
