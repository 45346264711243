<template>
  <div class="devPhaseContainer">
    <div>
      <p class="devPhaseTitle">
        {{$t('No data available')}}
      </p>
    </div>
    <div>
      <v-icon color="gray" size="150">
        mdi-database-remove
      </v-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: "NoDataSkeleton"
}
</script>

<style scoped>
.devPhaseContainer {
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.devPhaseTitle {
  font-size: 2rem;
  color: #002D5F;
  font-weight: 100;
}
</style>