<template>
  <div class="mainContainer">
    <div class="headerContainer">
      <div style="color: #002D5F; font-weight: 400">
        {{$t('Filter')}}:
      </div>
      <div class="infoContainer">
        <v-dialog v-model="dialog" max-width="400">
          <template v-slot:activator="{ on, attrs}">
            <v-btn
                icon
                color="#002D5F"
                v-bind="attrs"
                v-on="on"
            >
              <v-icon>
                mdi-information
              </v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-title class="text-h5" style="color: #002D5F">
              {{$t('Legend')}}
            </v-card-title>
            <v-card-text>
              {{$t('Legend description')}}
            </v-card-text>
            <v-divider style="width: 90%; margin-left: auto; margin-right: auto;"/>

            <v-card-subtitle style="color: #002D5F" class="text-h6 mt-5">
              {{$t('Map items')}}
            </v-card-subtitle>
            <div class="legends ml-5 mr-5 mb-5">
              <div class="legendItem ml-2">
                <div class="activeItem it" style="background-color: rgb(0,128,0); width: 13px">

                </div>
                <div class="itText ml-2">
                  {{$t('Active vehicle description')}}
                </div>
              </div>

              <div class="legendItem ml-2">
                <div class="activeItem it">
                  <PulsingDot/>
                </div>
                <div class="itText ml-2">
                  {{$t('Active blip vehicle')}}
                </div>
              </div>

              <div class="legendItem ml-2">
                <div class="activeItem it" style="background-color: red; width: 13px">

                </div>
                <div class="itText ml-2">
                  {{$t('Inactive vehicle')}}
                </div>
              </div>
            </div>
            <v-divider style="width: 90%; margin-left: auto; margin-right: auto;"/>
            <v-card-subtitle style="color: #002D5F" class="text-h6 mt-5">
              {{$t('Filter')}}
            </v-card-subtitle>
            <div class="ml-5 mr-5 mb-5 legends2">
              <div class="legendItem2 ml-2">
                <AdriaCheckBox
                    :value="filter.active"
                    :text="$t('Active vehicles')"
                />
                <div class="itText ml-4">
                  {{$t('Active vehicles filter description')}}
                </div>
              </div>
            </div>

            <div class="ml-5 mr-5 mb-5 legends2">
              <div class="legendItem2 ml-2">
                <AdriaCheckBox
                    :value="filter.inactive"
                    :text="$t('Inactive vehicles')"
                />
                <div class="itText ml-4">
                  {{$t('Inactive vehicles filter description')}}
                </div>
              </div>
            </div>

            <div class="ml-5 mr-5 mb-5 legends2">
              <div class="legendItem2 ml-2">
                <div style="min-width: 30%;">
                  <AdriaCheckBox
                      :value="filter.blip"
                      :text="$t('Activity live')"
                  />
                </div>
                <div class="itText ml-4">
                  {{$t('Activity liver description')}}
                </div>
              </div>
            </div>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="#002D5F"
                  text
                  @click="dialog = false"
              >
                {{$t('Close')}}
              </v-btn>
            </v-card-actions>
          </v-card>

        </v-dialog>
      </div>
    </div>
    <div class="adriaCheckBoxContainer mt-4">
      <AdriaCheckBox
          key="active_vehicles_filter"
          :value="filter.active"
          :text="$t('Active vehicles')"
          @valueChange="(it) => $emit('filterChange', {...filter, active: it})"
      />
      <AdriaCheckBox
          key="inactive_vehicles_key"
          class="ml-10"
          :value="filter.inactive"
          @valueChange="(it) => $emit('filterChange', {...filter, inactive: it})"
          :text="$t('Inactive vehicles')"
      />
      <AdriaCheckBox
          key="blip_key"
          class="ml-10"
          :value="filter.blip"
          @valueChange="(it) => $emit('filterChange', {...filter, blip:it})"
          :text="$t('Activity live')"
      />
    </div>
  </div>
</template>

<script>
import AdriaCheckBox from "@/components/AdriaCheckBox";
import PulsingDot from "@/components/PulsingDot";

export default {
  name: "AdriaMachMapLegend",
  components: {PulsingDot, AdriaCheckBox},
  data: () => {
    return {
      dialog: false,
      slider: 0
    }
  },
  props: ['filter'],
  methods: {
    onFilterChange2(it) {
      this.$emit('filterChange', {...this.filter, inactive: it})
    }
  }
}
</script>

<style scoped>
.adriaCheckBoxContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.headerContainer {
  position: relative;
}

.infoContainer {
  position: absolute;
  top: -15px;
  right: -15px
}

.legendItem {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.legendItem2 {
  display: flex;
  flex-direction: column;
}

.it {
  height: 10px;
  width: 10px;
  padding: 0;
  margin: 0;
  border-radius: 20px;
}

.itText {
  color: rgb(0, 0, 0, 0.5);
  font-size: 0.9rem;
}
</style>