<template>
  <div>
    <canvas :id="id" />
  </div>
</template>

<script>
import Chart from 'chart.js';

export default {
  name: "AdriaDoubleHorizontalBarGraph",
  props: ['data1', 'label1', 'data2', 'label2', 'labels', 'id', 'percentage', 'limited', 'unit', 'labelX', 'labelY'],
  data() {
    return {
      valueGraphData: {
        type: 'bar',
        data: {
          labels: this.$props.labels,
          datasets: [
            {
              data: this.$props.data2,
              backgroundColor: "#002D5F",
              label: this.$props.label1
            },
            {
              data: this.$props.data1,
              backgroundColor: "#5A5A5A",
              label: this.$props.label2
            }
          ]
        },
        options: {
          indexAxis: 'y',
          tooltips: {
            callbacks: {
              label: (tooltipItems, data) => {
                if (this.$props.percentage) {
                  return data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index] + '%'
                } else {
                  return data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index]
                }
              }
            }
          },
          elements: {
            bar: {
              borderWidth: 2,
            }
          },
          responsive: true,
          plugins: {
            legend: {
              position: 'right',
            },
            title: {
              display: true,
              text: 'Chart.js Horizontal Bar Chart'
            }
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  min: 0,
                  max: this.limited ? 100 : this.limit,
                  stepSize: 10,
                  padding: 10,
                  callback: (value, index, ticks) => {
                    if (this.$props.percentage) {
                      if (value == 0) {
                        return '';
                      }
                      return value + ' %';
                    } else if (this.$props.unit) {
                      return value + ` ${this.$props.unit}`;
                    } else {
                      return value;
                    }
                  }
                },
                min: 0,
                max: 100,
                gridLines: {
                  borderDash: [8, 2],
                },
                scaleLabel: {
                  display: true,
                  labelString: this.$props.labelX ? this.$props.labelX : ''
                }
              },
            ],
            yAxes: [{
              ticks: {
                beginAtZero: true
              },
              scaleLabel: {
                display: true,
                labelString: this.$props.labelY ? this.$props.labelY : ''
              }
            }]
          }
        }
      }
    }
  },
  mounted() {
    const ctx = document.getElementById(this.id)
    new Chart(ctx, this.valueGraphData)
  }
}
</script>

<style scoped>

</style>