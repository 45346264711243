<template>
  <div class="snack_container">
    <v-snackbar v-model="show" :top="top" :color="color" :timeout="timeout">
      <v-alert :type="color" class="mt-2">
        <v-row align="center">
          <v-col class="grow">
            {{message}}
          </v-col>
        </v-row>
      </v-alert>
    </v-snackbar>
  </div>
</template>

<script>
import store from '../store/index'
import {Component, Vue } from "vue-property-decorator";
@Component
export default class Snackbar extends Vue {

  show = false
  top = true
  message = ''
  color = 'success'
  timeout = 3000

  created() {
    store.watch(
        state => state.snackbar.snackbar,
        () => {
          this.show  = true;
          this.message = this.$store.getters.getSnackbarMessage
          this.color = this.$store.getters.getSnackbarType
        }
    )
  }

}
</script>

<style scoped>
.snack_container {
  position: absolute;
}
</style>
