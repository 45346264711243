<template>
  <div class="pa-0 ma-0">
    <canvas :id="id"></canvas>
  </div>
</template>

<script lang="js">
import Chart from "chart.js";

export default {
  name: "HorizontalChartBar",
  props: ["chartData", "id", 'labelX', 'labelY'],
  data() {
    return {
      chart: {
        type: "horizontalBar",
        data: {
          labels: this.$props.chartData.labels,
          datasets: this.$props.chartData.items
        },
        options: {
          legend: {
            display: false
          },
          responsive: true,

          lineTension: 1,
          scales: {
            xAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  padding: 25
                },
                grid: {
                  offset: true
                },
                gridLines: {
                  borderDash: [8, 2],
                },
                scaleLabel: {
                  display: true,
                  labelString: this.$props.labelX ? this.$props.labelX : ''
                }
              }
            ],
            yAxes: [
              {
                gridLines: {
                  display: false
                },
                scaleLabel: {
                  display: true,
                  labelString: this.$props.labelY ? this.$props.labelY : ''
                }
              }
            ]
          },

        }
      }
    }
  },
  mounted() {
    const ctx = document.getElementById(this.id);
    new Chart(ctx, this.chart);
  }
}
</script>

<style scoped>

</style>
