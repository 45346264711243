<template>
  <v-row :class="bordered ? 'bordered' : ''">
    <v-col class="col-lg-4 pl-5 itemText pt-2 pb-2" style="font-size: 14px">
      {{ machId }}
    </v-col>
    <v-col class="col-lg-8 text-center itemText pt-2 pb-2" style="font-size: 14px">
      {{ kilometersBeautified }}
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "LatLngItem",
  props: {
    bordered: {
      type: Boolean,
      required: false,
      default: true
    },
    machId: {
      type: String,
      required: true
    },
    kilometers: {
      type: Number,
      required: true
    }
  },
  computed: {
    kilometersBeautified() {
      return `${Math.round(this.kilometers)} km`
    }
  }
}
</script>

<style scoped>
.itemText {
  color: #5A5A5A;
  font-size: 14px;
}

.bordered {
  border-bottom: 1px solid rgba(90, 90, 90, 0.4);
}
</style>