<template>
  <div class="mainContainer">
    <div class="genderContainer">
      <div class="genderItem">
        <div class="circle adriaBlue"/>
        <div class="ml-4 genderItemMainText" style="width: 30px">
          {{ $t('Male') }}
        </div>
        <div class="ml-8 genderItemPercentText">
          {{menPercent}} %
        </div>
      </div>
      <div class="genderItem">
        <div class="circle adriaGray"/>
        <div class="ml-4 genderItemMainText" style="width: 30px">
          {{  $t('Female') }}
        </div>
        <div class="ml-8 genderItemPercentText">
          {{womanPercent}} %
        </div>
      </div>
      <div class="genderItem">
        <div class="circle adriaWhite"/>
        <div class="ml-4 genderItemMainText" style="width: 30px">
          {{ $t('Other') }}
        </div>
        <div class="ml-8 genderItemPercentText">
          {{otherPercent}} %
        </div>
      </div>
    </div>
    <div class="small-box ml-4">
      <canvas id="gender-chart"></canvas>
    </div>
  </div>
</template>
<script lang="js">
import Chart from 'chart.js'
export default {
  name: "UserGenderChart",
  props: ["genderData"],
  data() {
    return {
      userGenderData: {
        type: "doughnut",
        data: {
          labels: [
            'Moški',
            'Ženske',
            'Neznano'
          ],
          datasets: [{
            data: this.$props.genderData,
            backgroundColor: [
              '#002D5F',
              '#878787',
              '#e0dcdc'
            ],
          }]
        },
        options: {
          rotation: Math.PI,
          circumference: Math.PI,
          legend: {
            display: false
          },
          elements: {
            arc: {
              borderWidth: 0
            }
          }
        },
      }
    }
  },
  mounted() {
    const ctx = document.getElementById('gender-chart');
    new Chart(ctx, this.userGenderData);
  },
  computed: {
    menPercent () {
      return Math.floor(this.genderData[0] / (this.genderData[0] + this.genderData[1] + this.genderData[2]) * 100)
    },
    womanPercent () {
      return Math.floor(this.genderData[1] / (this.genderData[0] + this.genderData[1] + this.genderData[2]) * 100)
    },
    otherPercent () {
      return Math.floor(this.genderData[2] / (this.genderData[0] + this.genderData[1] + this.genderData[2]) * 100)
    }
  }
}
</script>

<style scoped>
.small-box {
  height: 50px;
  width: 100px;
}
.mainContainer {
  display: flex;
  align-items: center;
  background: none;
}
.genderContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.circle {
  width: 14px;
  height: 14px;
  border-radius: 50%;
}
.adriaBlue {
  background-color: #002D5F
}
.adriaGray {
  background-color: #878787;
}
.adriaWhite {
  background-color: #e0dcdc;
}
.genderItem {
  display: flex;
  justify-content: center;
  align-items: center;
}
.genderItemMainText {
  font-size: 14px;
  color: black;
}
.genderItemPercentText {
  font-weight: bold;
  font-size: 14px;
  color: black;
}
</style>
