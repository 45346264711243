<template>
  <div class="devPhaseContainer">
    <div>
      <p class="devPhaseTitle">
        FUNKCIONALNOST JE V FAZI RAZVOJA
      </p>
    </div>
    <div>
      <v-icon color="gray" size="150">
        mdi-cog
      </v-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: "DevPhaseSkeleton"
}
</script>

<style scoped>
.devPhaseContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 90vh;
}
.devPhaseTitle {
  font-size: 2rem;
  color: #002D5F;
  font-weight: 100;
}
</style>
