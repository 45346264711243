<template>
  <v-list-item
      class="vertical-nav-menu-link"
      v-bind="$attrs"
      active-class="bg-gradient-primary white--text"
  >
    <v-list-item-title>
      {{ title }}
    </v-list-item-title>
  </v-list-item>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      required: true,
    }
  }
}
</script>

<style lang="scss" scoped>
$adriaModra: #002D5F;

.v-list-item {
  position: relative;
  z-index: 0;
}

.v-list-item_title {
  z-index: 2;
}

.vertical-nav-menu-link {
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  width: 100%;

  &.v-list-item--active {
    background: rgba(0, 45, 95, 0.1);
    border-radius: 0;
    opacity: 0.85;
    font-weight: 600;
    // @include elevationTransition();

    .v-list-item__title {
      color: $adriaModra;
    }
  }

  .v-list-item__title {
    color: $adriaModra;
  }
}

.v-application--is-ltr .v-list.v-sheet--shaped .v-list-item, .v-application--is-ltr .v-list.v-sheet--shaped .v-list-item::before, .v-application--is-ltr .v-list.v-sheet--shaped .v-list-item > .v-ripple__container {
  border-bottom-right-radius: 4px !important;
  border-top-right-radius: 4px !important;
}
</style>
