<template>
  <div v-if="minHeight" :style="{height: minHeight}">
    <canvas :id="id"/>
  </div>
  <div v-else>
    <canvas :id="id" />
  </div>
</template>

<script>
import Chart from "chart.js";

export default {
  name: "AdriaValueGraph",
  props: ["valueLabelData", "valueData", "id", "limited", "limit", "percentage", "unit", "invertAxis", 'minHeight', 'labelX', 'labelY'],
  data() {
    return {
      valueGraphData: {
        type: this.$props.invertAxis ? "bar" : "horizontalBar",
        data: {
          labels: this.$props.valueLabelData,
          datasets: [
            {
              data: this.$props.valueData,
              backgroundColor: "#002D5F",
            }
          ]
        },
        options: {
          maintainAspectRatio: this.$props.minHeight ? false : true,
          tooltips: {
            callbacks: {
              label: (tooltipItems, data) => {
                if (this.$props.percentage) {
                  return data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index] + '%'
                } else {
                  return data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index]
                }
              }
            }
          },
          elements: {
            bar: {
              borderWidth: 1,
            }
          },
          legend: {
            display: false
          },
          responsive: true,
          lineTension: 1,
          scales: {
            xAxes: [
              {
                ticks: {
                  min: 0,
                  max: this.limited ? 100 : this.limit,
                  stepSize: 10,
                  padding: 10,
                  callback: (value, index, ticks) => {
                    if (this.$props.percentage) {
                      if (value == 0) {
                        return '';
                      }
                      return value + ' %';
                    } else if (this.$props.unit && !this.$props.invertAxis) {
                      return value + ` ${this.$props.unit}`;
                    } else {
                      return value;
                    }
                  }
                },
                min: 0,
                max: 100,
                gridLines: {
                  borderDash: [8, 2],
                },
                scaleLabel: {
                  display: true,
                  labelString: this.$props.labelX ? this.$props.labelX : ''
                }
              },
            ],
            yAxes: [
              {
                ticks: {
                  padding: 10,
                  callback: (value, index, ticks) => {
                    if (!this.$props.invertAxis) {
                      return value
                    } else if (this.$props.unit) {
                      return value + ` ${this.$props.unit}`;
                    } else if (this.$props.percentage) {
                      return  value + ' %';
                    } else {
                      return value;
                    }
                  }
                },
                gridLines: {
                  display: false
                },
                barThickness: 16,
                maxBarThickness: 16,
                barPercentage: 1.0,
                scaleLabel: {
                  display: true,
                  labelString: this.$props.labelY ? this.$props.labelY : ''
                },
              }
            ]
          }
        }
      }
    }
  },
  mounted() {
    const ctx = document.getElementById(this.id)
    console.log(this.valueData, this.valueLabelData)
    new Chart(ctx, this.valueGraphData)
  }
}
</script>

<style scoped>

</style>