<template>
  <div class="pa-0 ma-0">
    <canvas id="age-chart"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js'

export default {
  name: "UserAgeChart",
  props: ["ageSpans", "maleCount", "femaleCount"],
  data() {
    return {
      userAgeData: {
        type: "horizontalBar",
        data: {
          labels: this.$props.ageSpans,
          datasets: [
            {
              data: this.$props.maleCount,
              backgroundColor: "#002D5F",
              borderColor: "#002D5F",
              borderWidth: 1,
            },
            {
              data: this.$props.femaleCount,
              backgroundColor: "#878787",
              borderColor: "#878787",
              borderWidth: 1,
            },
          ]
        },
        options: {
          legend: {
            display: false
          },
          responsive: true,

          lineTension: 1,
          scales: {
            xAxes: [
              {
                gridLines: {
                  borderDash: [8, 2],
                }
              }
            ],
            yAxes: [
              {
                gridLines: {
                  display: false
                }
              }
            ]
          },

        }
      }
    }
  },
  mounted() {
    const ctx = document.getElementById('age-chart');
    new Chart(ctx, this.userAgeData);
  }

}
</script>

<style scoped>

</style>
