<template>
  <v-row :class="bordered ? 'bordered' : ''">
    <v-col class="col-lg-4 pl-5 itemText pt-2 pb-2" style="font-size: 14px">
      {{ screenName }}
    </v-col>
    <v-col class="col-lg-8 text-center itemText pt-2 pb-2" style="font-size: 14px">
      {{ percentage }}
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ScreenActivityItem",
  props: {
    bordered: {
      type: Boolean,
      required: false,
      default: true
    },
    screen: {
      type: String,
      required: true
    },
    percentage: {
      type: String,
      required: true
    }
  },
  computed: {
    screenName() {
      const platformSpecific = ['Activity', 'ViewController', 'Dialog']
      let currScreenName = this.screen
      platformSpecific.forEach((it) => currScreenName = currScreenName.replace(it, ''))
      return currScreenName
    }
  }
}
</script>

<style scoped>
.itemText {
  color: #5A5A5A;
  font-size: 14px;
}

.bordered {
  border-bottom: 1px solid rgba(90, 90, 90, 0.4);
}
</style>
