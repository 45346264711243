<template>
  <v-container class="circle">
    <v-progress-circular
        :rotate="-90"
        :size="75"
        :width="10"
        :value="value"
        :color="color"
    >
      <slot name="main" />
    </v-progress-circular>
    <div class="side">
      <slot name="side"/>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "AdriaCirclePercentage",
  props: ['color', 'value']
}
</script>

<style scoped>
.circle {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.side {
  margin-left: 10px;
  width: 175px;
  height: 125px;
  overflow: auto;
  font-size: 0.9rem;
}
</style>