<template>
  <div class="mainContainer">
    <div class="genderContainer">
      <div class="genderItem">
        <div class="circle adriaBlue"/>
        <div class="ml-4 genderItemMainText">
          Android
        </div>
        <div class="ml-4 genderItemPercentText">
          {{androidPercentage}}%
        </div>
      </div>
      <div class="genderItem">
        <div class="circle adriaGray"/>
        <div class="ml-4 genderItemMainText">
          iOS
        </div>
        <div class="ml-4 genderItemPercentText">
          {{iosPercentage}}%
        </div>
      </div>
    </div>
    <div class="small-box ml-4">
      <canvas id="os-chart"></canvas>
    </div>
  </div>
</template>
<script lang="js">
import Chart from 'chart.js'
export default {
  name: "OperatingSystemChart",
  props: ["osData"],
  data() {
    return {
      osDataCurrent: {
        type: "doughnut",
        data: {
          labels: [
            'Android',
            'iOS',
          ],
          datasets: [{
            data: this.$props.osData,
            backgroundColor: [
              '#002D5F',
              '#878787'
            ],
          }]
        },
        options: {
          rotation: Math.PI,
          circumference: Math.PI,
          legend: {
            display: false
          },
          elements: {
            arc: {
              borderWidth: 0
            }
          }
        }
      }
    }
  },
  mounted() {
    const ctx = document.getElementById('os-chart');
    new Chart(ctx, this.osDataCurrent);
  },
  computed: {
    androidPercentage() {
      return Math.floor(this.osData[0] / (this.osData[0] + this.osData[1]) * 100)
    },
    iosPercentage() {
      return Math.floor(this.osData[1] / (this.osData[0] + this.osData[1]) * 100)
    }
  }
}
</script>

<style scoped>
.small-box {
  height: 50px;
  width: 100px;
}
.mainContainer {
  display: flex;
  align-items: center;
  background: none;
}
.genderContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.circle {
  width: 14px;
  height: 14px;
  border-radius: 50%;
}
.adriaBlue {
  background-color: #002D5F
}
.adriaGray {
  background-color: #878787;
}
.genderItem {
  display: flex;
  justify-content: center;
  align-items: center;
}
.genderItemMainText {
  font-size: 14px;
  color: black;
}
.genderItemPercentText {
  font-weight: bold;
  font-size: 14px;
  color: black;
}
</style>
